// extracted by mini-css-extract-plugin
export var activeTab = "mel-manager-module--activeTab--56569";
export var borderLine = "mel-manager-module--borderLine--97dae";
export var description = "mel-manager-module--description--4946a";
export var firstRow = "mel-manager-module--firstRow--e4529";
export var formContainer = "mel-manager-module--formContainer--28015";
export var formElement = "mel-manager-module--formElement--6256e";
export var formLabel = "mel-manager-module--formLabel--7a01f";
export var formSubmit = "mel-manager-module--formSubmit--42008";
export var formWrapper = "mel-manager-module--formWrapper--2e9b3";
export var goArrow = "mel-manager-module--goArrow--20184";
export var melImage = "mel-manager-module--melImage--2630e";
export var melMiddleImage = "mel-manager-module--melMiddleImage--a56eb";
export var middleImage = "mel-manager-module--middleImage--f6286";
export var middleRow = "mel-manager-module--middleRow--c115b";
export var middleText = "mel-manager-module--middleText--909c2";
export var middleTextPad = "mel-manager-module--middleTextPad--27352";
export var mobColHidden = "mel-manager-module--mobColHidden--05c40";
export var mobColPadding = "mel-manager-module--mobColPadding--f821d";
export var mobColVisible = "mel-manager-module--mobColVisible--a73c3";
export var regulatorImage = "mel-manager-module--regulatorImage--584a1";
export var regulatorRow = "mel-manager-module--regulatorRow--7c10d";
export var saveTimeCol = "mel-manager-module--saveTimeCol--5c8b7";
export var screenImg = "mel-manager-module--screenImg--fc5d1";
export var subTitle20 = "mel-manager-module--subTitle20--a9346";
export var tabCol = "mel-manager-module--tabCol--35e61";
export var tabContentWrapper = "mel-manager-module--tabContentWrapper--2c8ea";
export var tabImage = "mel-manager-module--tabImage--ef654";
export var tabRow = "mel-manager-module--tabRow--0c578";
export var tabWrapper = "mel-manager-module--tabWrapper--e753f";
export var tick = "mel-manager-module--tick--cec8d";
export var tickRow = "mel-manager-module--tickRow--365dd";