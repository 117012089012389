import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import goArrow from "../assets/icon-arrow.svg";
import melFeatured from "../assets/MEL/mel-hero-featured2x.png";
import MelCreate from "../assets/MEL/mel-create.jpg";
import MelPublish from "../assets/MEL/mel-publish.svg";
import MelTechLog from "../assets/MEL/mel-tech-log2x.png";
import MelOverview from "../assets/MEL/mel-overview.jpg";
import MelReview from "../assets/MEL/mel-manager-review.jpg";
import regulatorsGif from "../assets/MEL/regulators.gif";
import faaLogo from "../assets/MEL/logo-faa.svg";
import easaLogo from "../assets/MEL/logo-easa.svg";
import caaLogo from "../assets/MEL/logo-caa.svg";
import arubaLogo from "../assets/MEL/aruba.jpg";
import sanMarinoLogo from "../assets/MEL/san-marino.jpg";
import bermudaLogo from "../assets/MEL/bermuda.jpg";
import caymanLogo from "../assets/MEL/cayman.jpg";
import iomLogo from "../assets/MEL/logo-iom-caa.webp";
import dcaLogo from "../assets/MEL/dca.svg";
import airbus from "../assets/MEL/Airbus.svg";
import bombardier from "../assets/MEL/Bombardier.svg";
import dassault from "../assets/MEL/Dassault.svg";
import embraer from "../assets/MEL/Embraer.svg";
import beoing from "../assets/MEL/Boeing.svg";
import gulfstream from "../assets/MEL/Gulfstream.svg";
import transportCanada from "../assets/MEL/logo-transport-canada.svg";
import Tick from "../assets/icon-tick.svg";
import Seo from "../components/SEO";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Started from "../components/Layout/started";
import * as styles from "./mel-manager.module.less";
import * as Scroll from "react-scroll";

const { scroller } = Scroll;

const Title24 = styled.div`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 25px;
`;

const Title30 = styled.div`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: center;
  color: #242d41;
  margin-bottom: 30px;
`;

const SubTitle20 = styled.div`
  font-size: 20px;
  letter-spacing: 0.03px;
  text-align: center;
  color: #242d41;
  max-width: 650px;
  margin-bottom: 50px;
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
  margin-top: 90px;
`;

const GradientSectionReversed = styled.div`
  background-image: linear-gradient(to bottom, #f0f5f9, #ffffff);
  margin-bottom: 45px;
  margin-top: 90px;
`;

const TabTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #3382e6;
  text-align: center;
  margin-bottom: 10px;
`;

const Author = styled.p`
  font-weight: bold;
  @media (max-width: 451px) {
    text-align: center;
    font-size: 18px;
  }
`;

const StyledLogo = styled.img`
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}` : "0")};
  width: ${({ width }) => (width ? `${width}` : "auto")};
`;

const SupportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  @media (max-width: 451px) {
    gap: 20px;
    margin: 0 0 20px;
    ${StyledLogo} {
      margin: 0;
      width: 100px;
    }
  }
`;

const Text = styled.div`
  color: ${({ color }) => (color ? `${color}` : "#242d41")};
  font-size: ${({ size }) => (size ? `${size}` : "14px")};
  font-weight: ${({ weight }) => (weight ? `${weight}` : "400")};
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  line-height: ${({ lineHeight }) =>
    lineHeight ? `${lineHeight}` : "inherit"};
  text-transform: ${({ textTransform }) =>
    textTransform ? `${textTransform}` : "none"};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : "left")};
  @media (max-width: 451px) {
    margin: 20px 0;
  }
`;

const CustomerQuoteSection = styled.div`
  background-image: linear-gradient(to left, #1e5bb5, #062955);
  padding: 100px 0px;
  font-size: 24px;
  line-height: 1.58;
  color: rgba(255, 255, 255, 0.9);
  @media (max-width: 451px) {
    padding: 30px 0%;
  }
`;

const StyledPara = styled.p`
  @media (max-width: 451px) {
    text-align: center;
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  padding: ${({ padding }) => (padding ? `${padding}` : "0")};
  @media (max-width: 451px) {
    margin: 0;
    padding: 20px;
  }
`;

const Header = styled.div`
  font-size: ${({ size }) => (size ? `${size}` : "24px")};
  font-weight: ${({ weight }) => (weight ? `${weight}` : "400")};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}` : "1")};
  color: ${({ color }) => (color ? `${color}` : "#242d41")};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : "left")};
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  @media (max-width: 451px) {
    margin: 20px 0;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  @media (max-width: 451px) {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: center;
    margin: 0 0 20px;
    ${StyledLogo} {
      margin: 0;
      height: 40px;
    }
  }
`;

const TabButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  outline: none;
  &:focus {
    outline: none;
  }
`;

const TabLine = styled.div`
  width: 100%;
  position: absolute;
  height: 2px;
  ${(props) =>
    props.active
      ? "background-color: #3382e6;"
      : "background-color: transparent;"}
  ${TabButton}:hover & {
    background-color: #3382e6;
    transition: background-color 0.5s linear;
  }
`;

const TabNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${(props) =>
    props.active ? "background-color: #3382e6;" : "background-color: #c2daf8;"}
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #ffffff;
  ${TabButton}:hover & {
    background-color: #3382e6;
    transition: background-color 1000ms linear;
  }
`;

const TabInnerTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 25px;
`;

const TabText = styled.div`
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
`;

const TickWrapper = styled.div`
  margin-top: 40px;
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

const createTab = {
  title: "Create an MEL from scratch or have us bring in your existing MELs",
  text: "Issuing a new MEL is as simple as selecting the MMEL and using our powerful platform to alter the procedures and items to fit your operation.",
  text2:
    "If you have an existing aircraft of the same type, or registered in the same location, even better. Changes you've previously made to an MMEL never need to be performed twice. With a few easy clicks you can customise the MEL for a specific registry or bring in changes previously applied to a different aircraft.",
  text3:
    "Tailored individual aircraft MELs become the new normal, by simply selecting the appropriate configurations for each aircraft, while managing revisions at a fleet-level. This reduces overhead and ensures that crew are never having to cross-reference serial numbers.",
  img: MelCreate,
  ticks: [
    "Complete MEL creation without any formatting",
    "Full library of existing MMELs, regularly updated",
    "New MELs tailored to your operation and registry, automatically",
    "Easy to use, but powerful, web platform",
    "Fully compliant MELs issued digitally or for printing",
  ],
};

const reviseTab = {
  title:
    "Revising MELs is a manual process that requires checking for differences then adapting and reformatting your existing files. Not any more.",
  text: "As revisions to the MMELs are issued by OEMs, these changes are automatically identified by our platform and made available to you. From there, all you have to do is review the changes and decide whether you wish to accept them into your MEL and a new MEL is automatically drafted for you.",
  text2:
    "Changes made to other MELs in the fleet, such as in line with new regulator wording, are treated in exactly the same way. You choose the parts you want to include, and a new MEL can be issued instantly.",
  img: MelReview,
  ticks: [
    "Issue MEL revisions with just a few clicks",
    "Changes automatically identified as new MMELs are issued",
    "Ensure consistency between MELs by applying changes to a selection of MELs, or prepare changes for the entire fleet ready for the next MEL issuances",
  ],
};

const publishTab = {
  title: "MELs issued digitally and consistently",
  text: "Once created or revised, MELs are issued and formatted automatically. The MELs can be browsed in full through our web portal or issued as compliant PDFs for separate distribution.",
  img: MelPublish,
  ticks: [
    "Simplified MEL viewing through the web portal, unconstrained by the need to format for print",
    "Fully integrated with the TrustFlight Tech Log, instantly replacing the MEL for an aircraft when a new revision is approved ensuring all users are always using the latest revision",
    "MEL reviewing system for your regulator to quickly and easily view and approve your changes",
    "Full PDF export support to enable continued MEL distribution using your in-house systems or by printing",
  ],
};

const TickRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  span {
    margin-left: 10px;
    max-width: 480px;
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02px;
  color: #1e5bb5;
`;

export default class melManager extends Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      activeTab: "create",
    };
  }

  handleTabClick = (input) => {
    this.setState({ activeTab: input });
  };

  TabContent = () => {
    const { activeTab } = this.state;
    let content = createTab;
    if (activeTab === "create") content = createTab;
    if (activeTab === "revise") content = reviseTab;
    if (activeTab === "publish") content = publishTab;
    const tickContent = content.ticks.map((item) => (
      <TickRow className={styles.tickRow}>
        {tick} <span>{item}</span>
      </TickRow>
    ));
    return (
      <Row className={styles.tabContentWrapper}>
        <Col lg={6} md={12}>
          <AnimationOnScroll
            animateOnce
            animateIn="animate__fadeInLeft"
            offset={0}
          >
            <TabInnerTitle>{content.title}</TabInnerTitle>
            <TabText>{content.text}</TabText>
            {content.text2 && (
              <>
                <br />
                <TabText>{content.text2}</TabText>
              </>
            )}
            {content.text3 && (
              <>
                <br />
                <TabText>{content.text3}</TabText>
              </>
            )}
            <TickWrapper>{tickContent}</TickWrapper>
          </AnimationOnScroll>
        </Col>
        <Col lg={6} md={12}>
          <AnimationOnScroll
            animateOnce
            animateIn="animate__fadeInRight"
            offset={0}
          >
            <img src={content.img} alt="mel" className={styles.tabImage} />
          </AnimationOnScroll>
        </Col>
      </Row>
    );
  };

  render() {
    const { activeTab } = this.state;
    return (
      <Layout>
        <Seo
          title="MEL Manager"
          description="Issue and revise Minimum Equipment Lists (MELs) with ease for your entire fleet. Automate the creation, revision and publication of compliant MELs."
        />
        <PageHeader
          header="Hassle-free <br /><span>MEL Management</span>"
          subHeader="Issue and revise Minimum Equipment Lists (MELs) with ease for your entire fleet. Automate the creation, revision and publication of compliant MELs."
          linkText="Schedule a demo"
          bigHeader
          bigImage={melFeatured}
          scroll
          // whiteLink
          svgImage
          trial
          link="#schedule_demo_form"
          handleLinkScroll={(e) => {
            e.preventDefault();
            scroller.scrollTo("schedule_demo_form", {
              duration: 800,
              smooth: true,
              offset: -60,
            });
          }}
        />
        <Container>
          <Wrapper padding="120px 80px 0">
            <Header
              size="30px"
              weight="600"
              lineHeight="1.31"
              color="#242d41"
              textAlign="center"
              margin="0 0 60px 0"
            >
              Supported by
            </Header>
            <LogoWrapper margin="0 0 48px 0">
              <StyledLogo src={faaLogo} alt="faa" marginRight="74px" />
              <StyledLogo
                src={transportCanada}
                alt="transport canada"
                marginRight="74px"
              />
              <StyledLogo src={easaLogo} alt="easa" marginRight="74px" />
              <StyledLogo src={caaLogo} alt="caa" marginRight="74px" />
              <StyledLogo src={iomLogo} alt="isle of man" marginRight="74px" />
              <StyledLogo src={dcaLogo} alt="dca" />
            </LogoWrapper>
            <LogoWrapper>
              <StyledLogo
                src={sanMarinoLogo}
                alt="san marino"
                marginRight="74px"
              />
              <StyledLogo src={bermudaLogo} alt="bermuda" marginRight="74px" />
              <StyledLogo src={caymanLogo} alt="caa" marginRight="74px" />
              <StyledLogo src={arubaLogo} alt="aruba" />
            </LogoWrapper>
          </Wrapper>
        </Container>
        <Container>
          <Row className={styles.firstRow}>
            <Col lg={12} className={styles.saveTimeCol}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <Title24>
                  Save time whilst ensuring compliance and consistency
                </Title24>
                <p className={styles.description}>
                  Issue and revise MELs in a fraction of the time using a simple
                  but powerful MEL building platform. Designed from the
                  ground-up to simplify the task of MEL management, our system
                  saves countless hours of administrative work.
                </p>
                <p className={styles.description}>
                  Build MELs from scratch using an existing MMEL as a template,
                  or manage and revise your current MELs. Our service covers the
                  full MEL lifecycle from creation through each revision without
                  you needing to ever worry about formatting.
                </p>
                <StyledLink
                  onClick={() =>
                    document
                      .getElementById("regulators")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <span>Discover the MEL Manager for Regulators </span>
                  <img src={goArrow} alt="goArrow" className={styles.goArrow} />
                </StyledLink>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
        <GradientSection>
          <div className={styles.tabWrapper}>
            <div className={styles.borderLine} />
            <Row className={styles.tabRow}>
              <Col xs={4} className={styles.tabCol}>
                <TabTitle>Create</TabTitle>
                <TabButton onClick={() => this.handleTabClick("create")}>
                  <TabLine active={activeTab === "create"} />
                  <TabNumber active={activeTab === "create"}>1</TabNumber>
                </TabButton>
              </Col>
              <Col xs={4} className={styles.tabCol}>
                <TabTitle>Revise</TabTitle>
                <TabButton onClick={() => this.handleTabClick("revise")}>
                  <TabLine active={activeTab === "revise"} />
                  <TabNumber active={activeTab === "revise"}>2</TabNumber>
                </TabButton>
              </Col>
              <Col xs={4} className={styles.tabCol}>
                <TabTitle>Publish</TabTitle>
                <TabButton onClick={() => this.handleTabClick("publish")}>
                  <TabLine active={activeTab === "publish"} />
                  <TabNumber active={activeTab === "publish"}>3</TabNumber>
                </TabButton>
              </Col>
            </Row>
          </div>
          {this.TabContent()}
        </GradientSection>
        <Container>
          <Row className={styles.middleRow}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInUp"
              offset={0}
              className={styles.middleText}
            >
              <Title30>
                Fully integrated with the TrustFlight suite of products
              </Title30>
              <SubTitle20 className={styles.subTitle20}>
                The MEL Manager system can be used independently, or integrated
                with our Tech Log and Registry products.
              </SubTitle20>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInUp"
              offset={0}
              className={styles.middleImage}
            >
              <img
                src={MelTechLog}
                alt="mel"
                className={styles.melMiddleImage}
              />
            </AnimationOnScroll>
          </Row>
          <Row className={styles.firstRow}>
            <Col lg={4}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <Title24>Track, review and publish your changes</Title24>
                <p className={styles.description}>
                  Changes you make or propose to an MEL can be easily reviewed
                  by other members of your team, or even the regulator. Once a
                  change has been made, it can be instantly applied to other
                  aircraft of the same type, operating under the same regulator,
                  or even applied to your entire fleet.
                </p>
              </AnimationOnScroll>
            </Col>
            <Col lg={{ span: 7, offset: 1 }}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInRight"
                offset={0}
              >
                <img src={MelReview} alt="mel overview" />
              </AnimationOnScroll>
            </Col>
          </Row>
          <Row className={styles.firstRow}>
            <Col lg={7} className={styles.mobColHidden}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <img
                  src={MelOverview}
                  alt="mel overview"
                  className={styles.screenImg}
                />
              </AnimationOnScroll>
            </Col>
            <Col lg={{ span: 4, offset: 1 }} className={styles.mobColPadding}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInRight"
                offset={0}
              >
                <Title24>Instant oversight of your entire fleet</Title24>
                <p className={styles.description}>
                  From a single location you are able to have oversight of your
                  entire fleet. View the current MELs, see the status of draft
                  MELs and ensure that revisions are issued well before their
                  deadlines with just a few clicks.
                </p>
              </AnimationOnScroll>
            </Col>
            <Col lg={7} className={styles.mobColVisible}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <img
                  src={MelOverview}
                  alt="mel overview"
                  className={styles.screenImg}
                />
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
        <GradientSectionReversed id="regulators">
          <Container>
            <Row className={styles.regulatorRow}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInUp"
                offset={0}
                className={styles.middleTextPad}
              >
                <Title30>
                  Powerful and simple to review, with tools built just for
                  regulators.
                </Title30>
                <SubTitle20>
                  MELs built using the platform contain a full history back
                  through every MMEL revision. With tools built just for
                  regulators, our platform makes it easy to keep on top of MEL
                  reviews.
                </SubTitle20>
              </AnimationOnScroll>
              <Row>
                <Col lg={7} className={styles.mobColHidden}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInLeft"
                    offset={0}
                  >
                    <img src={regulatorsGif} alt="mel overview" />
                  </AnimationOnScroll>
                </Col>
                <Col lg={5} className={styles.mobColPadding}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInRight"
                    offset={0}
                  >
                    <p className={styles.description}>
                      Reviewing an MEL for approval becomes a simple task. No
                      need to spot-check items or compare documents, the
                      platform shows you clearly what text has been altered or
                      updated since the last issue, and how it differs from the
                      latest MMEL. Instead of reviewing the whole document, you
                      only need to check the differences.
                    </p>
                    <div className={styles.tickRow}>
                      {tick}{" "}
                      <span>
                        Instantly see the status of all MELs on your registry,
                        or within your approval scope
                      </span>
                    </div>
                    <div className={styles.tickRow}>
                      {tick}{" "}
                      <span>
                        Review MELs in just a few clicks with clearly indicated
                        changes, ensuring you always know what's new
                      </span>
                    </div>
                    <div className={styles.tickRow}>
                      {tick}{" "}
                      <span>
                        Full history view that traces each MEL item back to the
                        MMEL
                      </span>
                    </div>
                    <div className={styles.tickRow}>
                      {tick}{" "}
                      <span>
                        Ensure consistency and compliance from the start with
                        our field validation and automated document creation
                      </span>
                    </div>
                    <div className={styles.tickRow}>
                      {tick}{" "}
                      <span>
                        Built-in approval process, no signatures or paperwork to
                        fill out
                      </span>
                    </div>
                  </AnimationOnScroll>
                </Col>
                <Col lg={7} className={styles.mobColVisible}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInLeft"
                    offset={0}
                  >
                    <img src={regulatorsGif} alt="mel overview" />
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Row>
          </Container>
        </GradientSectionReversed>
        <Container>
          <Text
            margin="35px 0 60px 0"
            size="30px"
            weight="600"
            lineHeight="1.31"
            color="#242d41"
            textAlign="center"
          >
            Supported aircraft
          </Text>
          <SupportWrapper margin="0 0 73px 0">
            <StyledLogo
              width="185px"
              src={airbus}
              alt="airbus"
              marginRight="95px"
            />
            <StyledLogo
              width="185px"
              src={beoing}
              alt="boeing"
              marginRight="74px"
            />
            <StyledLogo width="227px" src={bombardier} alt="bombardier" />
          </SupportWrapper>
          <SupportWrapper margin="0 0 150px 0">
            <StyledLogo
              width="232px"
              src={gulfstream}
              alt="gulfstream"
              marginRight="62px"
            />
            <StyledLogo
              width="202px"
              src={embraer}
              alt="embraer"
              marginRight="85px"
            />
            <StyledLogo
              width="185px"
              src={dassault}
              alt="dassault"
              marginRight="46px"
            />
          </SupportWrapper>
        </Container>
        <CustomerQuoteSection>
          <Container>
            <StyledPara>
              “TrustFlight has been supporting our operations for the past
              months in both the development and implementation of a new MEL.
              The MEL Manager is exactly what we needed. It cut the time needed
              to develop a new MEL from several months to a few days. We would
              recommend using TrustFlight to anyone looking for a highly
              productive and solution driven team, and an efficient and useful
              MEL platform. We plan to continue to work with them for the long
              term.”
            </StyledPara>
            <Author>Rudolf Winter, Air JPL</Author>
          </Container>
        </CustomerQuoteSection>
        <a id="schedule_demo_form" aria-label="Schedule a demo form" />
        <Container>
          <Started
            title="Ready to get started?"
            text="Talk to our sales team with any questions you might have – discover how MEL manager could enhance your operation."
            hubSpotFormId="3d242a47-1a7a-4bb7-b8f6-c7c1ddae411f"
          />
        </Container>
        <AlsoInterestedIn page="mel" />
      </Layout>
    );
  }
}
